// custom less mixins:

// most of them are taken from:
// http://designshack.net/articles/css/10-less-css-examples-you-should-steal-for-your-projects/

// equalheight columns
.equalheight{
  padding-bottom: 10000em;
  margin-bottom: -10000em;
}

// rotation
.rotation(@deg:5deg){
  -o-transform: rotate(@deg);
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  transform: rotate(@deg);
}

// .border-radius(20px);
.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

// .border-radius-custom(20px, 20px, 0px, 0px);
.border-radius-custom (@topleft: 5px, @topright: 5px, @bottomleft: 5px, @bottomright: 5px) {
  -webkit-border-radius: @topleft @topright @bottomright @bottomleft;
  -moz-border-radius: @topleft @topright @bottomright @bottomleft;
  border-radius: @topleft @topright @bottomright @bottomleft;
}

// .box-shadow(5px, 5px, 6px, 0.3);
.box-shadow (@x: 0px, @y: 3px, @blur: 5px, @alpha: 0.5) {
  -webkit-box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
  -moz-box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
  box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
}

// .gradient(left, #663333, #333333);
.gradient (@origin: left, @start: #ffffff, @stop: #000000) {
  background-color: @start;
  background-image: -webkit-linear-gradient(@origin, @start, @stop);
  background-image: -moz-linear-gradient(@origin, @start, @stop);
  background-image: -o-linear-gradient(@origin, @start, @stop);
  background-image: -ms-linear-gradient(@origin, @start, @stop);
  background-image: linear-gradient(@origin, @start, @stop);
}

// background-color: BADA55;
// .quick-gradient(top, 0.2);
// -- this use the background color + gradient from transparent to black --
.quick-gradient (@origin: left, @alpha: 0.2) {
  background-image: -webkit-linear-gradient(@origin, rgba(0,0,0,0.0), rgba(0,0,0,@alpha));
  background-image: -moz-linear-gradient(@origin, rgba(0,0,0,0.0), rgba(0,0,0,@alpha));
  background-image: -o-linear-gradient(@origin, rgba(0,0,0,0.0), rgba(0,0,0,@alpha));
  background-image: -ms-linear-gradient(@origin, rgba(0,0,0,0.0), rgba(0,0,0,@alpha));
  background-image: linear-gradient(@origin, rgba(0,0,0,0.0), rgba(0,0,0,@alpha));
}
