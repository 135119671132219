//*// CUSTOMIZED PLONE BREADCRUMBS //*//

@plone-breadcrumb-bg: transparent;
@plone-breadcrumb-padding-vertical: 6px;


.plone-breadcrumb {
  opacity: 0.6;
  .container {
    padding-left: 0;
  }
  clear: left;
  background-color: @plone-breadcrumb-bg;
  margin-bottom: 6px;
  margin-top: -6px;
  > * > span { //you are here
    display: inline-block;
    color: @plone-gray-light;
    padding: @plone-breadcrumb-padding-vertical 0;
    font-weight: @plone-font-weight-regular;
    @media (max-width: @plone-screen-sm-min) {
      display: none;
    }
  }
  ol {
    display: inline-block;
    padding: @plone-breadcrumb-padding-vertical - 2 0 @plone-breadcrumb-padding-vertical 0;
    list-style: none;
    margin: 0;
    > li {
      font-weight: @plone-font-weight-regular;
      display: inline-block;
      line-height: 0; // match ol & span height
      position: relative;
      padding: 0 @plone-breadcrumb-padding-horizontal*1.5 0 0;
      margin-left: 0px;
      + li:after {
        content: "";
        position: absolute;
        padding: 0 5px;
        color: @plone-breadcrumb-bg;
        border-left: 5px solid;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        left: -14px;
        top: -4px;
        padding: 0 !important;
      }
      + li:before {
        content: "";
        position: absolute;
        padding: 0 5px;
        color: @plone-breadcrumb-color;
        border-left: 5px solid;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        left: -14px;
        top: -4px;
        padding: 0 !important;
      }
    }
    a {
      color: @plone-link-color-on-grey;
    }
    > #breadcrumbs-current {
      color: @plone-breadcrumb-active-color;
    }
  }
  /* uncomment to hide breadcrumbs on home
  body.section-front-page & {display: none;} //not breadcrumbs on homepage
  */
}
