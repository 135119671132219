@boostrap_path: "node_modules/bootstrap/less";
@barceloneta_path: "barceloneta/less";

//*// vanilla boostrap imports:
//@import "@{boostrap_path}/bootstrap.less";
//@import "@{boostrap_path}/grid.less";
//@import "@{boostrap_path}/scaffolding.less";
//@import "@{boostrap_path}/print.less";
//@import "@{boostrap_path}/buttons.less";
//@import "@{boostrap_path}/variables.less";
//@import "@{boostrap_path}/mixins/border-radius.less";
@import "@{boostrap_path}/mixins/gradients.less";


//*// Plone imports:

@import "@{barceloneta_path}/fonts.plone.less";
@import "@{barceloneta_path}/variables.plone.less";
    @import "@{barceloneta_path}/mixin.prefixes.plone.less";
    @import "@{barceloneta_path}/mixin.tabfocus.plone.less";
    @import "@{barceloneta_path}/mixin.images.plone.less";
    @import "@{barceloneta_path}/mixin.forms.plone.less";
    @import "@{barceloneta_path}/mixin.borderradius.plone.less";
    @import "@{barceloneta_path}/mixin.buttons.plone.less";
    @import "@{barceloneta_path}/mixin.clearfix.plone.less";
    @import "@{barceloneta_path}/mixin.gridframework.plone.less"; //grid Bootstrap
    @import "@{barceloneta_path}/mixin.grid.plone.less"; //grid Bootstrap
    @import "@{barceloneta_path}/mixin.font.plone.less";

@import "@{barceloneta_path}/normalize.plone.less";
@import "@{barceloneta_path}/print.plone.less";
@import "@{barceloneta_path}/code.plone.less";

//*// Core CSS
@import "@{barceloneta_path}/grid.plone.less";
@import "@{barceloneta_path}/scaffolding.plone.less";
@import "@{barceloneta_path}/type.plone.less";
@import "@{barceloneta_path}/tables.plone.less";
@import "@{barceloneta_path}/forms.plone.less";
@import "@{barceloneta_path}/buttons.plone.less";
@import "@{barceloneta_path}/states.plone.less";

//*// Components
//@import "@{barceloneta_path}/breadcrumbs.plone.less";
@import "plone_customized/breadcrumbs.plone.less";
@import "@{barceloneta_path}/pagination.plone.less";
@import "@{barceloneta_path}/formtabbing.plone.less"; //pattern
@import "@{barceloneta_path}/views.plone.less";
@import "@{barceloneta_path}/thumbs.plone.less";
@import "@{barceloneta_path}/alerts.plone.less";
@import "@{barceloneta_path}/portlets.plone.less";
@import "@{barceloneta_path}/controlpanels.plone.less";
@import "@{barceloneta_path}/tags.plone.less";
//@import "@{barceloneta_path}/contents.plone.less";
@import "plone_customized/contents.plone.less";

//*// Patterns
@import "@{barceloneta_path}/accessibility.plone.less";
@import "@{barceloneta_path}/toc.plone.less";
@import "@{barceloneta_path}/dropzone.plone.less";
@import "@{barceloneta_path}/modal.plone.less";
@import "@{barceloneta_path}/pickadate.plone.less";
@import "@{barceloneta_path}/sortable.plone.less";
@import "@{barceloneta_path}/tablesorter.plone.less";
@import "@{barceloneta_path}/tooltip.plone.less";
@import "@{barceloneta_path}/tree.plone.less";

//*// Structure
//@import "@{barceloneta_path}/header.plone.less";
//@import "@{barceloneta_path}/sitenav.plone.less";
//@import "@{barceloneta_path}/main.plone.less";
//@import "@{barceloneta_path}/footer.plone.less";
@import "@{barceloneta_path}/loginform.plone.less";
@import "@{barceloneta_path}/sitemap.plone.less";

//*// Products
@import "@{barceloneta_path}/event.plone.less";
@import "@{barceloneta_path}/image.plone.less";
@import "@{barceloneta_path}/behaviors.plone.less";
@import "@{barceloneta_path}/discussion.plone.less";
@import "@{barceloneta_path}/search.plone.less";


// local imports:
@import "_mixins.less";
//@import "_plone_member.less";
@import "social_bookmarks.less";
//@import "search_box.less";

@grid-columns:              12;
@grid-gutter-width:         30px;
@grid-float-breakpoint:     768px;

// Scaffolding
@body-bg:    #fff;
@text-color: #333;
@colorLink: #1132c0;
@colorLinkHover: #3a55c9;
@colorAdminRed: 5d1414;

@headerHeight: 255px;
@footerHeight: 35px;

// l-tango settings:
@colorTango: #6700B0;
@colorFestival: #D10000;
@colorGalerie: #009C6D;
@colorVerein: #FF8000;

li {
  display: block;
}

@import url(http://fonts.googleapis.com/css?family=Ubuntu+Condensed);

// grid definitions:
.outer-wrapper {
  .make-row();
  max-width: 1090px;
  margin: 0 auto;

  #header-wrapper{
    .make-sm-column(12);

    #header {
      .make-row();
      height: @headerHeight;

      #header-left{
        .make-sm-column(3);
        padding: 80px 0 0 1px;
      }
      #header-right{
        .make-sm-column(9);
        height: @headerHeight;
        padding-top: 200px;

        #header-bottom {
          .make-row();

          .sectionDescription {
            .make-sm-column(8);

          }

          .portalSearchBox {
            .make-sm-column(4);
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
  #main-wrapper {
    .make-sm-column(12);

    #main {
      .make-row();

      #portal-column-one {
        .make-sm-column(3);
        .make-sm-column-pull(9);
        padding-top: 2.5em;
        // background: @gray-light;
      }

      #portal-column-content {
        .make-sm-column(9);
        .make-sm-column-push(3);
        padding-right: 0;
        // background: @gray-lighter;
      }
    }
  }
  #portal-footer-wrapper{
    .make-sm-column(12);

    #portal-footer {
      .make-row();

      #footer-left {
        .make-sm-column(3);

      }

      #footer-right {
        .make-sm-column(9);
        text-align: right;

      }
    }
  }
}

body{
  width: 100%;
  background: transparent top center url("../images/background.jpg") no-repeat;
  background-attachment: fixed;
  //line-height: 1.6em;
}

h1{
  font-size: 300%;

}
h2{
  font-size: 200%;
}

h3{
  font-size: 180%;
}

h4{
  font-size: 150%;
}

h5{
  font-size: 130%;
}

ul{
  padding-left: 0.2em;
  li {
    list-style-type: circle;
  }
}
ol{
  padding-left: 0.2em;
}

// #portal-breadcrumbs li {
//   display: block;
//   float: left;
//   margin-right: 0.4em;
// }


.documentDescription{
  font-weight: bold;
  margin-bottom: 1em;
}

#main-wrapper {
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, .5);
}

#header-left{
  position: relative;
  left:0;

  #portal-logo{
    position: absolute;
    top: 30px;
    left: -55px;
  }

}

#portal-globalnav {
  font-size: 28px;
  font-family: 'Ubuntu Condensed', Verdana, "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  padding-left: 0.4em;

  li {
    list-style-type: none;
    &:before {
      content: '...';
    }
    a{
      color: @text-color;
    }
  }
  #portaltab-tango{
    padding-left: 22px;
  }
  #portaltab-galerie{
    padding-left: 32px;
  }
  #portaltab-verein{
    padding-left: 14px;
  }

}

#header-right{
  background: transparent center center url("../images/header_tanzpaar.png") no-repeat;
}

#header-bottom{
  //background: rgba(255, 255, 255, 1);
  #gradient > .horizontal(rgba(255, 255, 255, 0.5); #fff; 0%; 60%);
  height: 2.1em;
  //.quick-gradient(left, 0.6);
  //.gradient(left, #663333, #333333);

  .sectionDescription {
    font-size: 1em;
    font-weight: bold;
    line-height: 2.1em;
  }

  .portalSearchBox{
    position: relative;
  }

}

#portal-footer-wrapper{
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, .6);
  height: @footerHeight;
  border-top: dotted 1px @text-color;
  border-bottom: dotted 1px @text-color;
  padding: 0.4em;
  position: fixed;
  bottom: 0;
  font-size: 90%;
  #portal-footer{
    li {
      list-style-type: none;
      display: inline;
      margin-right: 0.4em;
      // a{
      //   color: @text-color;
      // }
    }

  }
}

.blockHighlight{
  color: #fff;
  margin: 1em 0 1em 0;
  padding: 0.8em 0.8em 0.8em 1em;
  .border-top-radius(3px);
  .border-bottom-radius(3px);
}

.portlet{

}

.portlet.portletNavigationTree{
  border: 0;
  padding-top: 0;
  ul{
    padding-left: 0;
  }
  li{
    padding-left: 0.8em;
    line-height: 1.8em;
    list-style-type: none;
    a{
      color: @text-color;
      line-height: 1.2em !important;
    }
    &.navTreeLevel0.navTreeItemInPath{
      font-weight: bold;
    }
    &.navTreeCurrentNode{
      > a {
        border-left: 4px solid;
        padding-left: 4px !important;
        margin-left: -4px;
      }
    }
  }
}

//.icons-on .navTree li > a {
//  padding-left: 0.4em !important;
//}

.section-tango{
  h1, h2, h3, h4, h5, h6{
    color: @colorTango;
  }
  .sectionDescription{
    color: @colorTango;
  }
  .plone-breadcrumb{
    &, a, ol > li + li:before {
      color: @colorTango;
    }
  }
  .blockHighlight{
    background-color: @colorTango;
  }
  #header-right{
    background: transparent -44px 0px url("../images/bildmontage_tango.png") no-repeat;
  }
  #portaltab-tango{
    color: @colorTango;
    a{
      color: @colorTango;
    }
  }
  li.navTreeItemInPath,
  li.navTreeCurrentNode{
    > a{
      color: @colorTango;
    }
  }
  #livesearchLegend{
    background-color: @colorTango;
    boder: 1px solid @colorTango;
    color: #fff;
  }
}

.section-festival{
  h1, h2, h3, h4, h5, h6{
    color: @colorFestival;
  }
  .sectionDescription{
    color: @colorFestival;
  }
  .plone-breadcrumb{
    a, &, ol > li + li:before { color: @colorFestival; }
  }
  .blockHighlight{
    background-color: @colorFestival;
  }
  #header-right {
    background: transparent center center url("../images/bildmontage_festival.png") no-repeat;
  }
  #portaltab-festival{
    color: @colorFestival;
    a{
      color: @colorFestival;
    }
  }
  li.navTreeItemInPath,
  li.navTreeCurrentNode{
    > a{
      color: @colorFestival;
    }
  }
  #livesearchLegend{
    background-color: @colorFestival;
    boder: 1px solid @colorFestival;
    color: #fff;
  }
}

.section-galerie{
  h1, h2, h3, h4, h5, h6{
    color: @colorGalerie;
  }
  .sectionDescription{
    color: @colorGalerie;
  }
  .plone-breadcrumb{
    a, &, ol > li + li:before { color: @colorGalerie; }
  }
  .blockHighlight{
    background-color: @colorGalerie;
  }
  #header-right {
    background: transparent center center url("../images/bildmontage_galerie.png") no-repeat;
  }
  #portaltab-galerie{
    color: @colorGalerie;
    a{
      color: @colorGalerie;
    }
  }
  li.navTreeItemInPath,
  li.navTreeCurrentNode{
    > a{
      color: @colorGalerie;
    }
  }
  #livesearchLegend{
    background-color: @colorGalerie;
    boder: 1px solid @colorGalerie;
    color: #fff;
  }
}

.section-verein{
  h1, h2, h3, h4, h5, h6{
    color: @colorVerein;
  }
  .sectionDescription{
    color: @colorVerein;
  }

  .plone-breadcrumb{
    a, &, ol > li + li:before { color: @colorVerein; }
  }

  .blockHighlight{
    background-color: @colorVerein;
  }
  #header-right {
    background: transparent center center url("../images/bildmontage_verein.png") no-repeat;
  }
  #portaltab-verein{
    color: @colorVerein;
    a{
      color: @colorVerein;
    }
  }
  li.navTreeItemInPath,
  li.navTreeCurrentNode{
    > a{
      color: @colorVerein;
    }
  }
  #livesearchLegend{
    background-color: @colorVerein;
    boder: 1px solid @colorVerein;
    color: #fff;
  }
}


//taken from plone css:
.hiddenStructure {
    display: block;
    background: none repeat scroll 0% 0% transparent;
    border: medium none;
    height: 0.1em;
    overflow: hidden;
    padding: 0px;
    margin: -0.1em 0px 0px -0.1em;
    width: 1px;
}

#portal-siteactions {
  padding-left: 0;
  #personaltools-dashboard a {
    color: @colorAdminRed;
  }
  #personaltools-preferences a {
    color: @colorAdminRed;
  }
  #personaltools-plone_setup a {
    color: @colorAdminRed;
  }
  #personaltools-logout a {
    color: @colorAdminRed;
  }
}
