.shr-bookmarks .socials {
	width: 100% !important; margin: 0 !important; padding: 0 !important; float: left !important; background: transparent none !important; border: 0 none !important; outline: 0 none !important;
}

.shr-bookmarks .shr {
	background-repeat: no-repeat !important; display: inline !important; float: left !important; list-style-type: none !important; padding: 0 !important; cursor: pointer !important; margin: 0px 0 0 !important; background-color: transparent !important; border: 0 none !important; outline: 0 none !important; clear: none !important;
	height: 16px !important; width: 22px !important;
}

.shr-bookmarks .shr:before, .shr-bookmarks .shr:after, .shr-bookmarks .shr a:before, .shr-bookmarks .shr a:after { 
	content:'' !important;
}

.shr-bookmarks .socials a, .shr-bookmarks .socials a:hover {
	display:block !important; text-indent:-9999px !important; background-color:transparent !important; text-decoration:none !important; border:0 none !important; margin:0 !important; padding:0 !important;
	width:22px !important; height:16px !important;
}

.shr-bookmarks .socials a:hover, .shr-bookmarks .socials li:hover {
	background-color: transparent !important; border: 0 none !important; outline: 0 none !important;
}

.shr-facebook {background-position:left bottom !important; }
.shr-facebook:hover {background-position:left top !important; }
.shr-google {background-position:-22px bottom !important; }
.shr-google:hover {background-position:-22px top !important; }
.shr-twitter {background-position:-44px bottom !important; }
.shr-twitter:hover {background-position:-44px top !important; }
.shr-xing {background-position:-66px bottom !important; }
.shr-xing:hover {background-position:-66px top !important; }

.shr-bookmarks {
    height: 100%; overflow: hidden; position: relative; padding: 0 0 0 10px; top: 0px; margin: 0 -5px 0 10px; 
}

.shr-bookmarks .shr {
	background-image:url('images/shr-sprite.png') !important;
}


